<template>
  <div class="cancel-dialog">
    <el-dialog title="账户注销" append-to-body :visible.sync="dialogVisible" width="60%" @before-close="close">
      <div
        class="cancel-bar"
        v-for="{ tital, describe, type, root } in cancelOption"
        :key="tital"
        @click="selectLogout(type)"
      >
        <template v-if="root">
          <div class="cancel-bar-tital">{{ tital }}</div>
          <div class="cancel-bar-describe">{{ describe }}</div>
          <div class="cancel-bar-arrow el-icon-arrow-right"></div>
        </template>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="close">关 闭</el-button>
      </span>
    </el-dialog>
    <ApplyDialog :ApplyDialogVisible.sync="ApplyDialogVisible" @goBack="open"></ApplyDialog>
    <ViolationDialog :ViolationDialogVisible.sync="ViolationDialogVisible" @goBack="open"></ViolationDialog>
  </div>
</template>

<script>
import { cancelOption } from './option'
import ApplyDialog from '../applyDialog'
import ViolationDialog from '../violationDialog'
export default {
  components: {
    ApplyDialog,
    ViolationDialog
  },
  props: {
    cancelDialogVisible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      cancelOption,
      dialogVisible: false,
      ApplyDialogVisible: false,
      ViolationDialogVisible: false
    }
  },
  computed: {},
  watch: {
    cancelDialogVisible: {
      handler(n) {
        this.dialogVisible = n
      }
    },
    dialogVisible: {
      handler(n) {
        this.$emit('update:cancelDialogVisible', n)
      }
    }
  },
  methods: {
    close() {
      this.$emit('update:cancelDialogVisible', false)
    },
    open() {
      this.$emit('update:cancelDialogVisible', true)
    },
    selectLogout(type) {
      switch (type) {
        case 0:
          this.close()
          this.ApplyDialogVisible = !this.ApplyDialogVisiblerue
          break
        case 1:
          this.close()
          this.ViolationDialogVisible = !this.ViolationDialogVisible
        default:
          return
      }
    }
  }
}
</script>

<style scoped lang="scss">
.cancel-bar {
  display: flex;
  justify-content: space-between;
  border: 1px solid #ccc;
  line-height: 60px;
  margin-bottom: 20px;
  cursor: pointer;
  .cancel-bar-tital {
    padding-left: 20px;
    width: 200px;
  }
  .cancel-bar-describe {
    flex: 1;
  }
  .cancel-bar-arrow {
    width: 30px;
    &::before {
      line-height: 60px;
    }
  }
}
</style>
