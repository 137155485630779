import { checkPermission } from '@/utils'
export const cancelOption = [
  {
    tital: '申请注销流程',
    describe: '(需用户满足条件，手机号认证)',
    type: 0,
    root: checkPermission('externaladmin:user:list:morethanBtn:cancellation:1')
  },
  {
    tital: '账号违规注销流程',
    describe: '(平台审核注销)',
    type: 1,
    root: checkPermission('externaladmin:user:list:morethanBtn:cancellation:2')
  }
]
