<template>
  <div>
    <el-dialog title="账户注销" append-to-body :visible.sync="dialogVisible" width="60%">
      <div class="violationDialog">
        <div class="violation-dialog-head">
          <p class="head-title">注销流程</p>
          <p class="head-describe">(①填写账号注销原因-②注销成功)</p>
        </div>
        <div class="violation-dialog-table">
          <p class="table-title">用户信息</p>
          <table class="table-box">
            <tr>
              <td>用户名: {{ tableData && tableData.nickName }}</td>
              <td>手机号(登录账号): {{ tableData && tableData.mobile }}</td>
              <td>邮箱: {{ tableData && tableData.email }}</td>
            </tr>
            <tr>
              <td>余额: ￥{{ tableData && tableData.balance }}</td>
              <td>未完成订单: {{ tableData && tableData.umCompleteOrders }}</td>
              <td>最近订单完成时间: {{ tableData && latestTransactionDate(tableData.latestTransactionDate) }}</td>
            </tr>
          </table>
        </div>
        <el-form :model="form" :rules="rules" ref="ruleForm" inline>
          <el-form-item label="账号注销原因" prop="cancellationReason">
            <el-checkbox-group v-model="form.cancellationReason">
              <el-row v-for="{ label, reason } in REASON" :key="reason">
                <el-checkbox :label="reason" name="reason">{{ label }}</el-checkbox>
                <el-form-item
                  v-if="reason === 'otherReason' && form.cancellationReason.includes('otherReason')"
                  prop="otherReason"
                >
                  <el-input
                    v-model="form.otherReason"
                    type="textarea"
                    :rows="2"
                    resize="none"
                    style="margin-left: 10px"
                    class="reason-textarea"
                  ></el-input>
                </el-form-item>
              </el-row>
            </el-checkbox-group>
          </el-form-item>
        </el-form>
        <div class="violation-dialog-tip">
          <span class="el-icon-warning-outline"></span>
          <span>提示: 账号一旦注销成功，将无法恢复，注销操作不可逆。用户将无法登录此账号，请慎重操作</span>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="goBack">上一步</el-button>
        <el-button type="danger" @click="goAhead">确认注销</el-button>
      </span>
    </el-dialog>
    <RequireDialog
      :RequireDialogVisible.sync="RequireDialogVisible"
      @requiredHandle="requiredHandle"
      :loading="requireLoading"
      :tableData="tableData"
    ></RequireDialog>
  </div>
</template>

<script>
import { REASON } from './option'
import RequireDialog from '../requireDialog'
import { confirmCancell, cancellUserInfo } from '@/api/user'
import { parseTime } from '@/utils/parse'
export default {
  components: { RequireDialog },
  props: {
    ViolationDialogVisible: {
      type: Boolean,
      default: false
    }
  },
  inject: ['rowData', 'init'],
  data() {
    return {
      REASON,
      RequireDialogVisible: false,
      form: {
        cancellationReason: [],
        otherReason: ''
      },
      rules: {
        cancellationReason: [{ type: 'array', required: true, message: '请至少选择一项', trigger: 'change' }],
        otherReason: [{ required: true, message: '请输入', trigger: 'blur' }]
      },
      dialogVisible: false,
      requireLoading: false,
      tableData: null
    }
  },
  computed: {
    latestTransactionDate() {
      return (time) => {
        return parseTime(time)
      }
    }
  },
  watch: {
    ViolationDialogVisible: {
      handler(n) {
        this.dialogVisible = n
      }
    },
    dialogVisible: {
      handler(n) {
        this.$emit('update:ViolationDialogVisible', n)
        if (n && !this.tableData) {
          this.getCancellUserInfo()
        }
      }
    }
  },
  methods: {
    close() {
      this.$emit('update:ViolationDialogVisible', false)
    },
    goAhead() {
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          console.log(111)
          this.RequireDialogVisible = !this.RequireDialogVisible
        } else {
          return false
        }
      })
    },
    goBack() {
      this.close()
      this.$emit('goBack')
    },
    async requiredHandle() {
      this.requireLoading = true
      try {
        let { otherReason, cancellationReason } = this.form
        const query = {
          otherReason,
          cancellationReason,
          cancellationUserId: this.tableData.id,
          isUsed: 1
        }
        const { code } = await confirmCancell(query)
        if ($SUC({ code })) {
          this.$message.success('操作成功')
        } else {
          this.$message.error('操作失败')
        }
      } catch {}
      this.requireLoading = false
      this.RequireDialogVisible = false
      this.close()
      this.init()
    },
    async getCancellUserInfo() {
      try {
        this.tableLoading = true
        const { detail } = await cancellUserInfo({ id: this.rowData.id })
        this.tableData = detail
        console.log('this.tableData', this.tableData)
        this.tableLoading = false
      } catch {
        this.tableLoading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
.violationDialog {
  .violation-dialog-head {
    p {
      display: inline-block;
      margin-top: 0;
    }
    .head-title {
      margin-right: 10px;
      font-size: 16px;
      font-weight: 600;
    }
  }
  ::v-deep .violation-dialog-table {
    .table-box {
      width: 100%;
      border-collapse: collapse;
      td {
        height: 30px;
        border: 1px solid #ebeef5;
        padding-left: 10px;
      }
    }
  }
  ::v-deep .reason-textarea + .el-form-item__error {
    left: 10px;
  }
  .violation-dialog-tip {
    text-align: right;
    color: red;
  }
}
</style>
